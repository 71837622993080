import me from "../img/me.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";
import mock6 from "../img/mock6.png";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

// export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
export let colors = ["rgb(62, 228, 237)", "rgb(30, 0,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Harsh",
  lastName: "Patel",
  initials: "hp", // the example uses first and last, but feel free to use three or more if you like.
  position: "a 4th-year Computer Science student at the University of Waterloo 🎒",
  selfPortrait: me, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  summary: `From creating scalable fullstack systems to tackling vehicle localization, I enjoy working on challenging problems!\n
I'm passionate about backend engineering, machine learning and robotics.\n
With 2+ years of work experience in software engineering roles, I'm always ready to acquire new skills, and contribute to interesting projects!\n
Outside of tech, you'll find me learning tennis, watching a show, or thinking about my next meal`,
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "🚀",
      // text: "understanding autonomous vechicles",
      text: "Creating scalable full-stack applications or tackling vehicle localization problems",
    },
    {
      emoji: "🤖",
      // text: "computer science at University of Waterloo",
      text: "Passionate about backend engineering, machine learning and robotics",
    },
    {
      emoji: "👨🏽‍💻",
      text: (
        <>
          Previously built projects at{' '}
          <a href="https://www.constantcontact.com/" target="_blank" rel="noopener noreferrer"><strong>Constant Contact</strong></a>,{' '}
          <a href="https://ottomotors.com/" target="_blank" rel="noopener noreferrer"><strong>OTTO Motors</strong></a>, and{' '}
          <a href="https://www.ncr.com/" target="_blank" rel="noopener noreferrer"><strong>NCR Corporation</strong></a>
        </>
      )
      // text: "Previously built projects at Constant Contact, OTTO Motors and NCR Corporation",
      // text: "Always ready to acquire new skills, and contribute to interesting projects",
    },
    {
      emoji: "🎾",
      text: "Currently learning tennis, watching a show, or thinking about my next meal",
    },
  ],
  socials: [
    {
      link: "https://www.linkedin.com/in/harsh-patell/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://github.com/harsh-patell",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "mailto: harsh.patel2@uwaterloo.ca",
      icon: "fa fa-envelope-o",
      label: "email",
    },
    {
      link: "https://drive.google.com/file/d/1WQZhrCAheHQwrkeYBwAK5i0-Jt1vAlLP/view?usp=sharing",
      icon: "fa fa-file-o",
      label: "resume",
    },
    // {
    //   link: "https://instagram.com",
    //   icon: "fa fa-instagram",
    //   label: "instagram",
    // },
    // {
    //   link: "https://twitter.com",
    //   icon: "fa fa-twitter",
    //   label: "twitter",
    // },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Hello! I'm John. I'm a systems engineer for Google. I studied CompSci at Harvard, I enjoy long walks on the beach, and I believe artificial intelligence will inevitably rule us all one day. You should hire me!",
  skills: {
    proficientWith: [
      "javascript",
      "react",
      "git",
      "github",
      "bootstrap",
      "html5",
      "css3",
      "figma",
    ],
    exposedTo: ["nodejs", "python", "adobe illustrator"],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "theater",
      emoji: "🎭",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "cooking",
      emoji: "🌶",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      title: "Employee Review Predictor",
      source: "https://github.com/harsh-patell/employee-review-predictor",
      image: mock6,
      description:
        "Utilized a Glassdoor employee reviews dataset to create an MLP neural network and a random forest regressor for predicting the ratings that an employee would give to their employer",
    },
    {
      title: "Find My Face",
      source: "https://github.com/harsh-patell/find-my-face",
      image: mock5,
      description:
        "Facial detector developed in Python using a VGG16 architecture. It is trained on personal image data with object classification and regression, and real-time face detection using TensorFlow and OpenCV",
    },
    {
      title: "Chess AI",
      source: "https://github.com/harsh-patell/chess-ai",
      image: mock1,
      description:
        "Classic Chess in C++ with a custom built AI for move generation. The game is developed with OOP principles and design patterns. It can be played through a GUI and on the terminal",
    },
    {
      title: "Reinveted Spotify Heardle",
      source: "https://github.com/harsh-patell/Spotify-Karoke",
      image: mock3,
      description:
        "A Wordle-inspired music guessing game that allows users to guess songs and artists from their playlists on Spotify. Customized to allow users to type or sing the next lyric",
    },
    {
      title: "StockValue",
      source: "https://github.com/harsh-patell/stock-predictor-lstm",
      image: mock2,
      description:
        "A stock predictor application trained with a long-short term memory model to perform time-series forecasting. The app allows users to enter a ticker symbol and see detailed visualizations through Matplotlib",
    },
    {
      title: "Shopaway",
      source: "https://github.com/harsh-patell/shopaway",
      image: mock4,
      description:
        "Chrome extension to improve the online shopping experience by allowing users to save items they wish to purchase to a generalized shopping kart that is organized based on the store and type of item",
    },
  ],
};
